import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import tw, { styled } from 'twin.macro'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Stripes from '@elements/Stripes'
import LinkButton from '@elements/LinkButton'
import Standings from '@elements/Standings'
import { strings } from '../../static/strings'
import moment from 'moment/min/moment.min.js'
import orderBy from 'lodash/orderBy'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { motion } from 'framer-motion'
import Modal from '@elements/Modal'
import useGoogleSheets from 'use-google-sheets'

const Section = tw.section`relative overflow-hidden bg-white z-0 after:absolute after:bg-primaryDark  after:w-full after:top-0 after:right-0 after:bottom-0 after:z-[-1]`
const ImageWrap = tw.div`relative h-[400px] md:h-auto md:min-h-[650px]`
const Text = tw.p`absolute text-sm text-white w-32 tracking-1 md:tracking-2 italic z-10`

const StyledStripes = tw(Stripes)`bottom-0`

const CountryCode = tw.span`inline-block text-sm text-white uppercase font-futuraBook pl-3 relative bottom-1 tracking-1`

const NextRaceWrap = tw.div`relative ml-auto px-offset-xs`
const Overline = tw.p`tracking-1 md:tracking-2 text-white text-sm uppercase pt-offset-xs pb-6`
const Title = tw.p`font-futuraExtra text-white mb-4`
const Time = tw.div`flex flex-col md:flex-row`
const TimeBig = tw.p`text-[100px] text-right md:text-left md:text-5xl lg:text-7xl xl:text-[90px] 3xl:text-[150px] leading-none text-white font-futuraExtra `
const TimeLabel = styled.p(({ variant }) => [
	tw`pl-4 text-sm text-white uppercase md:pl-0 md:pt-4 tracking-1 md:tracking-2 `,
	variant === 'countdown'
		? tw`md:translate-y-0 md:translate-x-[-18px] xl:translate-x-[-27px] 3xl:translate-x-[-40px]`
		: tw`translate-y-[12px] md:translate-y-0 md:translate-x-[-16px] lg:translate-x-[-25px] xl:translate-x-[-25px] 3xl:translate-x-[-38px]`,
])

const TimeSeparator = styled.span`
	${tw`hidden md:block text-[100px] leading-[0px] w-24 md:w-auto md:text-5xl lg:text-7xl xl:text-[90px] 3xl:text-[150px] md:leading-none text-white font-futuraExtra md:mx-2`}
`
const Flex = tw.div`flex md:flex-col flex-row items-center justify-center`
const TimeLabelWrap = styled.div`
	${tw`w-1/4 md:w-auto`}
	.last {
		transform: translateX(0);
		@media screen and (max-width: 767px) {
			transform: ${({ variant }) => variant !== 'countdown' && 'translate(0px,12px)'}; //adding a star to label when input is required
		}
	}
`
const TimeWrap = tw.div`w-1/2 md:w-auto flex flex-col md:block items-end`
const TimeColumn = tw.div`flex flex-col md:flex-row`
const WpAcfNextraceBlock = ({ data, locale }) => {
	const { isMobile } = useBreakpoint()
	const { anchor } = data.attributes
	const module = data.modulesNextRace
	const staticData = useStaticQuery(graphql`
		{
			wp {
				acfOptionsRaceCalendar {
					calendar: optionsRaceCalendar {
						...DetailsPostRacesFragment
					}
					livestream: optionsLivestream {
						de {
							text
							livestream
							subpage
							youtube
							url
						}
						en {
							text
							subpage
							livestream
							youtube
							url
						}
					}
				}
			}
		}
	`)

	const [gsheet, setGsheet] = React.useState(null)
	const gSheetData = useGoogleSheets({
		apiKey: 'AIzaSyDqHcXpu3mJ5-KL5LA4Y3eCBDL7xorPRB8',
		sheetId: '1laFhSZRaQlElpcwnMVbUJrs0H-kLlXCusu0Whwxm50o',
		sheetsNames: ['Sheet1', 'Sheet2'],
	})

	const [sortedData, setSortedData] = React.useState(null)
	const [filteredDates, setFilteredDates] = React.useState([])
	const [raceDate, setRaceDate] = React.useState(null)
	const [currentDate, setCurrentDate] = React.useState(moment())

	const [days, setDays] = React.useState(null)
	const [hours, setHours] = React.useState(null)
	const [minutes, setMinutes] = React.useState(null)
	const [seconds, setSeconds] = React.useState(null)

	const getFilteredDates = () => {
		if (sortedData) {
			const __filteredDates = sortedData.filter((item, index) => {
				const today = moment().format('YYYY-MM-DD HH:mm:ss')
				const raceDate = moment(item.raceDate)
				if (item.endRaceDate) {
					const endRaceDate = moment(item.endRaceDate)
					return moment(today) < endRaceDate
				}
				const raceWeekend = raceDate.add(4, 'h')
				return moment(today) < raceWeekend
			})
			setFilteredDates(__filteredDates)
			setRaceDate(__filteredDates.length > 0 && __filteredDates[0]?.raceDate ? moment(__filteredDates[0].raceDate) : 'finished')
		}
	}

	const getSortedData = () => {
		let __secondDates = []
		let __dates = []
		staticData.wp.acfOptionsRaceCalendar.calendar.races.filter((item, index) => {
			if (item.secondRace) {
				const newRace = {
					duplication: true,
					secondRace: item.secondRace,
					raceDate: item.secondRaceDate,
					endRaceDate: item.endSecondRaceDate,
					serie: item.secondRaceSerie,
					trackPostLocation: item.trackPostLocation,
				}
				return __secondDates.push(newRace)
			}
		})
		__dates = staticData.wp.acfOptionsRaceCalendar.calendar.races
		__dates.push(...__secondDates)
		const __sortedData = orderBy(__dates, [(item) => item.raceDate], ['asc'])
		setSortedData(__sortedData)
	}

	React.useEffect(() => {
		getSortedData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		gSheetData.data.length !== 0 && setGsheet(gSheetData.data)
	}, [gSheetData.data])

	React.useEffect(() => {
		sortedData && getFilteredDates()
	}, [sortedData, days]) // eslint-disable-line react-hooks/exhaustive-deps

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (raceDate !== 'finished' && raceDate && filteredDates) {
				let timeBetween = moment.duration(raceDate.diff(moment().format('YYYY-MM-DD HH:mm:ss')))
				let days = parseInt(timeBetween.asDays())
				let hours = timeBetween.subtract(moment.duration(days, 'days')).hours()
				let minutes = timeBetween.subtract(moment.duration(hours, 'hours')).minutes()
				let seconds = timeBetween.subtract(moment.duration(minutes, 'minutes')).seconds()
				setCurrentDate(moment().format('YYYY-MM-DD HH:mm:ss'))
				setDays(('0' + days).slice(-2))
				setHours(('0' + hours).slice(-2))
				setMinutes(('0' + minutes).slice(-2))
				setSeconds(('0' + seconds).slice(-2))
			}
		}, 1000)
		return () => clearInterval(interval)
	}, [raceDate])

	const lang = locale.substr(0, 2)

	const getTeaserUri = (nextRaceLocation) => {
		if (nextRaceLocation?.locale?.locale === locale) {
			return nextRaceLocation?.uri
		}
		if (nextRaceLocation?.translated[0]?.locale?.locale === locale) {
			return nextRaceLocation?.translated[0]?.uri
		}
		return false
	}

	const bulletVariants = {
		init: {
			opacity: 0,
		},
		animate: {
			opacity: [1, 0.5, 1],
			transition: {
				times: [0, 0.1, 0.2, 1],
				repeat: Infinity,
				duration: 2,
			},
		},
	}
	const getVideoId = (url) => {
		url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
		return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0] // eslint-disable-line
	}
	const [modalOpened, setModalOpened] = React.useState(false)

	const checkLivestream = () => {
		if (staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.livestream === 'youtube') {
			setModalOpened(true)
			return
		}
		if (staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.livestream === 'subpage') {
			navigate(staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.subpage)
			return
		}
		if (staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.livestream === 'url') {
			window.open(staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.url, '_blank')
			return
		}
		return ''
	}

	return (
		<>
			<Section
				{...(anchor !== '' ? { id: anchor } : {})}
				className={`emf-mb-spacing ${module.layout !== 'default' ? 'lg:after:w-8/12 2xl:after:w-9/12' : ''}  ${raceDate === 'finished' ? 'md:pb-spacingXl-master xl:pb-[200px]' : ''}`}
			>
				<ImageWrap>
					{module.text && <Text className='emf-x-offset emf-top-offset'>{module.text}</Text>}
					<div className='absolute top-0 bottom-0 left-0 w-full after:absolute after:bg-white after:h-full after:w-full after:top-0 after:right-0 after:bottom-0 after:z-0'>
						<StyledStripes atFront />
					</div>
					<GatsbyImage
						image={getImage(isMobile && module.imageMobile?.localFile ? module.imageMobile?.localFile : module.image?.localFile)}
						loading='eager'
						alt={isMobile && module.imageMobile?.altText ? module.imageMobile?.altText : module.image?.altText || ''}
						className='object-cover relative h-full w-full z-0 max-h-[700px] md:min-h-[650px]'
					/>
				</ImageWrap>
				{raceDate !== 'finished' && filteredDates.length > 0 && (
					<NextRaceWrap className={module.layout !== 'default' ? 'md:w-8/12 2xl:w-9/12' : ''}>
						<Overline>{strings[lang].nextRace}</Overline>
						{raceDate && raceDate - moment(currentDate) > 0 && (
							<>
								<Title className='headline-h2'>
									{filteredDates[0].trackPostLocation?.title}
									{filteredDates[0].trackPostLocation?.postTypeRaces?.countryCode && <CountryCode>({filteredDates[0].trackPostLocation?.postTypeRaces?.countryCode})</CountryCode>}
								</Title>

								<Time>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>{days}</TimeBig>
												<TimeSeparator variant='countdown'>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel variant='countdown'>{strings[lang].days}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>{hours}</TimeBig>
												<TimeSeparator variant='countdown'>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel variant='countdown'>{strings[lang].hours}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>{minutes}</TimeBig>
												<TimeSeparator variant='countdown'>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel variant='countdown'>{strings[lang].minutes}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>{seconds}</TimeBig>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap variant='countdown'>
											<TimeLabel className='last'>{strings[lang].seconds}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
								</Time>
							</>
						)}
						{raceDate - moment(currentDate) < 0 && (
							<>
								{(staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.youtube ||
									staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.subpage ||
									staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.url) && (
									<div className='flex flex-col w-full gap-3 md:flex-row md:justify-between 2xl:justify-start'>
										{days > -2 ? <Title className='headline-h2 md:w-1/2 2xl:w-auto'>Race is on</Title> : <Title className='headline-h2 md:w-1/2 2xl:w-auto'>To be announ&shy;ced</Title>}
										<div className='flex items-center w-full gap-4 mt-5 ml-auto origin-left -skew-y-6 cursor-pointer md:mt-0 md:w-1/2 2xl:w-1/3' onClick={() => checkLivestream()}>
											<motion.div variants={bulletVariants} initial='init' animate='animate' className='w-4 h-4 skew-y-6 bg-red-600 rounded-full xl:w-6 xl:h-6 live-bullet'></motion.div>
											<div className='text-2xl uppercase xl:text-4xl live-text text-superLight font-futuraBold'>
												{staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.text || strings[lang].toLivestream}
											</div>
										</div>
									</div>
								)}
								<Time>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>--</TimeBig>
												<TimeSeparator>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel>{strings[lang].days}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>--</TimeBig>
												<TimeSeparator>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel>{strings[lang].hours}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>--</TimeBig>
												<TimeSeparator>:</TimeSeparator>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel>{strings[lang].minutes}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
									<Flex>
										<TimeWrap>
											<TimeColumn>
												<TimeBig>--</TimeBig>
											</TimeColumn>
										</TimeWrap>
										<TimeLabelWrap>
											<TimeLabel className='last'>{strings[lang].seconds}</TimeLabel>
										</TimeLabelWrap>
									</Flex>
								</Time>
							</>
						)}
						{module.button !== null && <LinkButton tw='relative mt-12 mb-spacingXs-xs' target={module.button?.target} label={module.button?.title} variant='light' href={module.button?.url} />}
						{module.button === null && <LinkButton tw='relative mt-12 mb-spacingXs-xs' label={strings[lang].raceDetails} variant='light' href={getTeaserUri(filteredDates[0].trackPostLocation)} />}
					</NextRaceWrap>
				)}
				{(raceDate === 'finished' || filteredDates.length === 0) && (
					<NextRaceWrap className={module.layout !== 'default' ? 'md:w-8/12 2xl:w-9/12' : ''}>
						<Overline>{lang === 'de' ? 'Nächstes Rennen:' : 'Next race:'}</Overline>
						<Title className='headline-h2'>{lang === 'de' ? 'tba' : 'Not sheduled yet'}</Title>
					</NextRaceWrap>
				)}
				{module.layout !== 'default' && <Standings lang={lang} sheetData={gsheet} loading={gsheet && gsheet?.length > 0} />}
				{/* <Standings lang={lang} /> */}
			</Section>
			<Modal modalContainerClassName='!h-auto sm:overflow-y-hidden max-w-screen-lg w-11/12 xl:w-7/12' state={modalOpened} onClick={() => setModalOpened(false)}>
				<Wrap>
					{modalOpened && staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.youtube && (
						<ModalIframeWrap>
							<iframe
								title={`EMF Racing ${getVideoId(staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.youtube)}`}
								src={`https://www.youtube-nocookie.com/embed/${getVideoId(staticData.wp.acfOptionsRaceCalendar?.livestream?.[lang]?.youtube)}?rel=0&autoplay=1`}
								frameBorder='0'
								className='!w-full !h-full '
								allow='autoplay; encrypted-media'
								allowFullScreen
							></iframe>
						</ModalIframeWrap>
					)}
				</Wrap>
			</Modal>
		</>
	)
}

const ModalIframeWrap = tw.div`aspect-w-16 aspect-h-9 2xl:aspect-none 2xl:!relative w-full max-w-[800px] min-h-[33%] 2xl:h-[500px] 2xl:w-full max-h-[450px] mx-auto`

const Wrap = styled.div`
	${tw`h-full overflow-y-auto pr-4 max-h-[60vh] w-full`}
`

export default WpAcfNextraceBlock
